@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    --gray: #ccc;
    --rgba-black: #00000080;
    --rgba-blue: #00000080; 
    --red: #a52a2a;
    --primary: #ff4500,
}

*{
  box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer;
  }
  
  /* Couleur de fond de la barre de défilement */
  /* Il s'agit de la partie non visible de la barre de défilement */
  ::-webkit-scrollbar-track {
    background-color: #003366;
  }
  
  /* Couleur de la poignée de défilement (le curseur) */
  ::-webkit-scrollbar-thumb {
    background-color: #ff4500;
  }
  
  /* Changement de couleur de la poignée de défilement lorsqu'elle est survolée */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #ff4500;
    cursor: pointer;
  }


.Toastify__toast--success{
  background-color: brown;
}

.Toastify__toast.Toastify__toast-theme--colored.Toastify__toast--success.Toastify__toast--close-on-click{
  background-color: green;
}

.Toastify__toast.Toastify__toast-theme--colored.Toastify__toast--error.Toastify__toast--close-on-click{
  background-color: brown;
}

/* Style des tableaux */
/* Style des tableaux */
.table-container {
  width: 100%;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  width: 100%;
}


thead{
  background-color: var(--primary);
  color: var(--white);
  font-size: 16px;
}


tr:nth-child(even) {
    background-color: var(--gray);
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #666;
}


td{
  font-size: 13px;
  white-space: nowrap;
}

th {
  font-size: 13px;
}
/* 
th::after {
  cursor: pointer;
  content: "";
} */