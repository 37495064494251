.form-textarea{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-textarea-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--gray-2);
}

.form-textarea-section.error{
    border: 1px solid var(--red);
}

.form-textarea-section-icon{
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: var(--gray-2);
    color: var(--black);
}

.form-textarea-section-textarea{
    flex-grow: 1;
    height: 80px;
    outline: none;
    border: 1px solid var(--gray);
    padding: 0 10px;
    font-weight: 400;
}

.form-textarea-section-textarea:focus{
    border: 1px solid orangered;
}

.form-textarea-section-textarea::placeholder{
    color: var(--gray);
    font-weight: 300;
}

.form-textarea-error{
    font-size: 10px;
    color: var(--red);
}

.form-label{
    font-size: 12px;
    margin-bottom: 2px;
}

.form-label span{
    color: red;
    font-size: 17px;
}

@media (max-width: 992px) {
    .form-textarea-section-icon{
        width: 40px;
    }


    .form-textarea{
        overflow: hidden;
    }
}