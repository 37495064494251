.form-select{
    display: flex;
    flex-direction: column;
}

.form-select-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--gray);
}

.form-select-section.error{
    border: 1px solid var(--red);
}

.form-select-section-icon{
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: var(--gray);
    color: var(--black);
}

.form-select-section-select{
    flex-grow: 1;
    min-height: 45px;
    outline: none;
    border: none;
    padding: 0 10px;
    overflow: hidden;
}

.form-select-section-select option{
    /* font-family: "Montserrat-Regular"; */
    /* max-width: 100%; */
}

.form-select-error{
    font-size: 10px;
    color: var(--red);
}

.form-label{
    font-size: 12px;
    margin-bottom: 2px;
}

.form-label span{
    color: red;
    font-size: 17px;
}

@media (max-width: 992px) {
    .form-select-section-icon{
        width: 40px;
        height: 40px;
    }

    .form-select-section-select{
        min-height: 50px;
    }

    .form-select{
        overflow: hidden;
    }
}