.form-input{
    display: flex;
    flex-direction: column;
}

.form-input-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--gray-2);
}

.form-input-section.error{
    border: 1px solid var(--red);
}

.form-input-section-icon{
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: var(--gray-2);
    color: var(--black);
}

.form-input-section-input{
    flex-grow: 1;
    height: 45px;
    outline: none;
    border: 1px solid var(--gray);
    padding: 0 10px;
    font-weight: 400;
}


.form-input-section-input::placeholder{
    color: var(--gray);
    font-weight: 300;
}

.form-input-error{
    font-size: 10px;
    color: var(--red);
}

.form-label{
    font-size: 12px;
    margin-bottom: 2px;
}

.form-label span{
    color: red;
    font-size: 17px;
}

@media (max-width: 992px) {
    .form-input-section-icon{
        width: 40px;
        height: 40px;
    }

    .form-input-section-input{
        height: 50px;
    }

    .form-input{
        overflow: hidden;
    }
}